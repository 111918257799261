// 保存数据
export const setSession = (key, value) => {
  localStorage.setItem(key, value);
  return true;
};

// 获取数据
export const getSession = (key) => {
  let value = localStorage.getItem(key);
  return value;
};

// 删除数据
export const removeSession = (key) => {
  localStorage.removeItem(key);
  return true;
};

// 清空数据
export const clearAll = () => {
  localStorage.clear();
  return true;
};
