// 导包
import { record, Replayer } from "rrweb";
import rrwebPlayer from "rrweb-player";
import { getSession } from "./sessionStorage";
import API from "../http/api";

// 全局存储
var events = [];
var stopFn = null;
// 录制
function operationRecording(obj = {}) {
    events = [];
    console.log("开始录制");
    let checkoutEveryNth = null;
    let checkoutEveryNms = null;
    if (obj['checkoutEveryNth']) {
        checkoutEveryNth = obj['checkoutEveryNth']
    }
    if (obj['checkoutEveryNms']) {
        checkoutEveryNms = obj['checkoutEveryNms']
    }

    stopFn = new record({
        emit(event, isCheckout) {
            // 自动提交数据
            // isCheckout 是一个标识，告诉你重新制作了快照
            if (isCheckout) {
                console.log("重新制作快照")
            }
            events.push(event)
            // console.log("录制==>>", JSON.stringify(event))
        },
        checkoutEveryNth: checkoutEveryNth, // 每 200 个 event 重新制作快照 单位个
        checkoutEveryNms: checkoutEveryNms, // 每5分钟重新制作快照 单位毫秒 5 * 60 * 1000
    });
}

// 存储
async function operationServe() {
    console.log("执行保存！")
    await sectionsSave(events);
    operationStopFn()
    return events;
}

//分段保存
async function sectionsSave(eve) {
    let domStr = JSON.stringify(eve);
    let uid = getSession("UUID")
    await API.insuranceAddDom({ domStr, domId: uid });
}

// 回放
function operationReplayer(doId, event) {
    console.log("回放！")
    let docId = document.getElementById(doId)
    let widths = document.body.clientWidth
    let heights = document.body.clientHeight

    docId.setAttribute('sandbox', 'allow-same-origin allow-scripts allow-popups allow-forms');

    let obj = new rrwebPlayer({
        target: docId, // 可以自定义 DOM 元素
        data: {
            events: event,
            width: widths,
            height: heights,
            showController: false,
        },
        UNSAFE_replayCanvas: true //支持回放 canvas 
    });
    obj.play()
    return obj;
}

// 停止
function operationStopFn() {
    console.log("停止录屏！")
    stopFn && stopFn();
}

// 导出
export default {
    operationRecording,
    operationServe,
    operationStopFn,
    operationReplayer
}