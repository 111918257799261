import http from "./http";
const qs = require('qs');

const headers = {
    "Content-Type": "application/json; charset=utf-8"
}
const fromData = {
    'Content-Type': 'application/x-www-form-urlencoded'
}
const fromFile = {
    "Content-Type": "multipart/form-data"
}

const baseURL = ""
const qhins = "/qhins"

export default {
    /**
     * 获取产品列表
     * @param {*} invitationCode 页面ID
     * @param { } key 搜索关键字
     *
     * https://api.91xbw.net/api/insurance/search?key=%E6%90%9C%E7%B4%A2&invitationCode=1002
     */
    getInsuranceSearch(data) {
        return http.get(baseURL + '/api/insurance/search', data, headers)
    },

    /**
     * 获取产品详情 https://api.91xbw.net/api/insurance?id=3
     * @param {} id ID
     */
    getInsuranceDetails(data) {
        return http.get(baseURL + '/api/insurance', data, headers)
    },

    /**
     * 获取手术级别 https://api.91xbw.net/api/common/operation_level?id=2462
     */
    getOperationLevel(data) {
        return http.get(baseURL + '/api/common/operation_level', data, headers)
    },

    /**
     * 获取年龄限制 https://api.91xbw.net/api/common/age_range?id=2462
     */
    getAgeRange(data) {
        return http.get(baseURL + '/api/common/age_range', data, headers)
    },

    /**
     * 搜索医院 https://api.91xbw.net/api/common/hospital?key=a
     */
    getHospital(data) {
        return http.get(baseURL + '/api/common/hospital', data, headers)
    },

    /**
     * 搜索医院 https://api.91xbw.net/api/common/hospital?key=a - 开放安澜的
     */
    getHospitalAnLan(data) {
        return http.get(baseURL + '/api/common/hospitalAnLan', data, headers)
    },

    /**
     * 投保 表单提交 post https://api.91xbw.net/api/insurance
     */
    postInsurance(data) {
        return http.fromReq(baseURL + "/api/insurance", data, fromData)
    },

    /**
     * 安澜投保 表单提交 post https://api.91xbw.net/api/insurance
     */
    postInsuranceAnLan(data) {
        return http.fromReq(baseURL + "/api/insurance/addAnLanInfo", data, fromData)
    },

    /**
     * 统计访问次数
     * @param {} invitationCode 邀请码ID
     */
    getCommonRequest(data) {
        return http.get(baseURL + '/api/common/request', data, headers)
    },

    /**
     * 保单查询接口：
     * /api/insurance/searchInsuranceInfo?phone=15914153143&appNo=0GTJ202012111399805818
     */
    searchInsuranceInfo(data) {
        return http.get(baseURL + "/api/insurance/searchInsuranceInfo", data, headers)
    },

    getImgCodeID(file) {
        const param = new FormData();
        param.append("file", file);
        return http.post(baseURL + '/api/insurance/checkIdCard', param, fromFile)
    },

    getFileStream(data) {
        const param = qs.stringify(data)
        let url = qhins + "/webins/epoliy?" + param
        return http.fileStream(url)
    },

    /**
     * 获取手机验证码
     * http://vfu4vr.natappfree.cc/sys/user/checkPhone
     * http://vfu4vr.natappfree.cc/api/login/{phone}
     */
    getPhoneCode(phone) {
        return http.get(baseURL + "/api/login/" + phone, {}, headers)
    },

    /**
     * 手机号登陆
     * @param {*} data
        phone	是	string	手机号
        code	是	string	状态码
     */
    postLogin(data = {}) {
        return http.fromReq(baseURL + "/api/login", data, fromData)
    },

    /**
     * 查询登录用户推广码
     * @param {*} data
     */
    getPromotionCode(data = {}) {
        return http.get(baseURL + "/api/business/getPromotionCode", data, headers)
    },

    /**
     * 查询当前销售营业额
     * @param {*} data
     */
    queryBusinessData(data = {}) {
        return http.get(baseURL + "/api/business/queryBusinessData", data, headers)
    },

    /**
     * 查询当前可推广多少产品
     * @param {*} data
     */
    queryProduct(data = {}) {
        return http.get(baseURL + "/api/business/queryProduct", data, headers)
    },

    /**
     * 上传录制视频
     * domStr dom对象的2M字符串
     * domNumber  当前第几块DOM字符串
     * domId  DOM字符串唯一标识ID
     * @param {*} data
     */
    insuranceAddDom(data = {}) {
        return http.fromReq(baseURL + "/api/insurance/addDom", data, fromData)
    },

    /**
     *  高速保投保
     * /api/gaoSuBao/save
     */
    gaoSuBaoSave(data) {
        return http.post(baseURL + "/api/gaoSuBao/save", data, headers)
    }
}
