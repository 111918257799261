import axios from "axios";
const qs = require("qs");
axios.defaults.timeout = 120000; // 请求超时
// axios.defaults.baseURL = "/api"; // 增加请求默认路径前缀
// axios.defaults.baseURL = "https://api.91xbw.net"

export default {
  /**
   * get 请求
   * @param url 接口路由
   * @returns {AxiosPromise<any>}
   */
  get(url, params, headers) {
    let options = {};

    if (params) {
      options.params = params;
    }
    if (headers) {
      options.headers = headers;
    }
    return axios.get(url, options);
  },

  /**
   * post 请求
   *
   * @param url 接口路由
   * @param params 接口参数
   * @returns {AxiosPromise<any>}
   */
  post(url, data, headers) {
    let options = {};

    if (headers) {
      options.headers = headers;
    }
    return axios.post(url, data, options);
  },

  /**
   * from 表单提交
   */
  fromReq(url, data, headers) {
    const options = {
      method: "POST",
      headers: headers,
      data: qs.stringify(data),
      url
    };
    return axios(options);
  },

  /**
   * get获取文件流
   */
  fileStream(url) {
    const options = {
      url: url,
      method: "get",
      responseType: "blob"
    };
    return axios(options);
  }
};
