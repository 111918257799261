// Action 提交的是 mutation，而不是直接变更状态。
// Action 可以包含任意异步操作。
// mutation用于改变state状态
// state存放数据状态

import Vue from "vue";
import Vuex from "vuex";

// 挂载插件
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    explain: {}, //说明
    planName: "", //页面名称

    feeId: "", //费率ID
    plantId: "", //产品ID
    typeId: "", //款ID
    codeId: "", //存储页面ID

    baiDuToken: "" //百度Toke
  },
  getters: {
    // 获取说明
    getExplain: (state) => {
      return state.explain;
    },
    //获取页面昵称
    getPlanName: (state) => {
      return state.planName;
    },
    // 获取费率ID
    getFeeId: (state) => {
      return state.feeId;
    },
    //获取产品ID
    getPlantId: (state) => {
      return state.plantId;
    },
    // 获取款ID
    getTypeId: (state) => {
      return state.typeId;
    },
    // 获取页面ID
    getCodeId: (state) => {
      return state.codeId;
    },
    // 获取百度Token
    getBaiDuToken: (state) => {
      return state.baiDuToken;
    }
  },
  mutations: {
    setCodeId(state, id) {
      state.codeId = id;
    },
    // 获取费率ID
    setFeeId(state, id) {
      state.feeId = id;
    },
    //获取产品ID
    setPlantId(state, id) {
      state.plantId = id;
    },
    // 获取款ID
    setTypeId(state, id) {
      state.typeId = id;
    },
    setExplain(state, item) {
      state.explain = item;
    },
    setPlanName(state, title) {
      state.planName = title;
    },
    // 设置百度token
    setBaiDuToken(state, item) {
      state.baiDuToken = item;
    }
  },
  actions: {
    // 异步操作时使用
    // increaseAsync({ state, commit }, payload) {
    //     setTimeout(() => {
    //         commit("increase");
    //     }, 1000);
    // },
    // submitLogin({ commit }, payload) {
    //     return new Promise(resolve => {
    //         setTimeout(() => {
    //             commit('login');
    //             resolve(true);
    //         }, 2000);
    //     })
    // }
  }
});
