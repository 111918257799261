import Vue from 'vue';
import Vant from 'vant';
import less from 'less';
import 'vant/lib/index.css';

import App from './App.vue';
import router from './router';
import axios from "axios";
import store from './store'

import VueResource from 'vue-resource';//从node_modules里边把vue-resource引入进来，同引入vue文件和引入vue-router一个道理

import api from "./http/api";
import "./http/axios";

import dayjs from 'dayjs'
import { Dialog } from 'vant';

import recordFun from "./util/recordFun";

// 对后端接口 进行全局注册，将api挂载到vue的原型上

Vue.use(less);
Vue.use(Vant);
Vue.use(VueResource);
Vue.prototype.$axios = axios;
Vue.prototype.$api = api;
Vue.prototype.$Dialog = Dialog;
Vue.prototype.dayjs = dayjs;
Vue.prototype.recordFun = recordFun;
Vue.config.productionTip = false;


new Vue({
  store,
  router,
  render: h => h(App)
}).$mount("#app")