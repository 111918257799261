<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import { uuid } from "./util/publicFun";
import { setSession, getSession } from "./util/sessionStorage";

export default {
      name: "app",
      components: {},

      methods: {
        // 生成ID
        generateUUID() {
          setSession("UUID", uuid());
        },
        // 录制
        startRecord() {
          this.recordFun.operationRecording();
        },
      },
      mounted() {
        this.generateUUID();
        this.startRecord();
      },
};
</script>

<style lang="less">
body {
  background: #f8f8f8;
}
.content {
  padding-bottom: 50px;
}
#video {
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-color: white;
}
#x-buttons {
  position: fixed;
  bottom: 0px;
  right: 0px;
}
</style>
