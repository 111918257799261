/**
 * 根据身份证获取信息
 * IdCard('142223198503226111',3)
 */
export function IdCard(UUserCard, num) {
  if (num == 1) {
    //获取出生日期
    // year, month, day, hours, minutes, seconds, milliseconds
    return {
      year: UUserCard.substring(6, 10),
      month: UUserCard.substring(10, 12),
      day: UUserCard.substring(12, 14)
    };
  }

  if (num == 2) {
    //获取性别
    if (parseInt(UUserCard.substr(16, 1), 10) % 2 == 1) {
      //男
      return "男";
    } else {
      //女
      return "女";
    }
  }

  if (num == 3) {
    //获取年龄
    var myDate = new Date();
    var month = myDate.getMonth() + 1;
    var day = myDate.getDate();
    var age = myDate.getFullYear() - UUserCard.substring(6, 10) - 1;
    if (
      UUserCard.substring(10, 12) < month ||
      (UUserCard.substring(10, 12) == month &&
        UUserCard.substring(12, 14) <= day)
    ) {
      age++;
    }
    return age;
  }
}

export function urlParse(url) {
  let obj = {};
  let reg = /[?&][^?&]+=[^?&%]+/g;
  let arr = url.match(reg);
  arr.forEach((item) => {
    let tempArr = item.substring(1).split("=");
    let key = decodeURIComponent(tempArr[0]);
    let val = decodeURIComponent(tempArr[1]);
    obj[key] = val;
  });
  return obj;
}

/**
 * 生成UUID
 */
export function uuid() {
  var s = [];
  var hexDigits = "0123456789abcdef";
  for (var i = 0; i < 36; i++) {
    s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
  }
  s[14] = "4"; // bits 12-15 of the time_hi_and_version field to 0010
  s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
  s[8] = s[13] = s[18] = s[23] = "-";

  var uuid = s.join("");
  return uuid;
}
