import Vue from "vue";
import Router from "vue-router";
import { getSession } from "../util/sessionStorage";
Vue.use(Router);

const originalPush = Router.prototype.push;
const originalReplace = Router.prototype.replace;
//push
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch((err) => err);
};
//replace
Router.prototype.replace = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalReplace.call(this, location, onResolve, onReject);
  return originalReplace.call(this, location).catch((err) => err);
};

let router = new Router({
  routes: [
    {
      path: "/",
      name: "官网",
      component: () => import("@/page/website")
    },

    //高速宝 项目打包  path: "/index/:code",  (nginx域名访问直接独立工程包) (不需要首页指向)
    {
      path: "/index/:code",
      name: "投保",
      component: () => import("@/page/GaoSuBaoOrder")
    },

    //手术意外险项目打包
    // {
    //   path: "/gaoSuBao/:code",
    //   name: "投保",
    //   component: () => import("@/page/GaoSuBaoOrder")
    // },
    // {
    //   path: "/index/:id",
    //   name: "首页",
    //   component: () => import("@/page/index")
    // },

    {
      path: "/productDetails/:id",
      name: "详情",
      component: () => import("@/page/productDetails")
    },
    {
      path: "/showExplain/",
      name: "并发证说明",
      component: () => import("@/page/showExplain")
    },
    {
      path: "/Important/:id",
      name: "重要通知",
      component: () => import("@/page/Important")
    },
    {
      path: "/touBao/:id",
      name: "投保",
      component: () => import("@/page/touBao")
    },
    {
      path: "/touBaoAnLan/:id",
      name: "投保",
      component: () => import("@/page/touBaoAnLan")
    },
    {
      path: "/query",
      name: "保单查询",
      component: () => import("@/page/InsuranceQuery")
    },
    {
      path: "/orderInquiry",
      name: "智慧云医",
      component: () => import("@/page/InsuranceQueryZhyy")
    },
    {
      path: "/login",
      name: "会员登录",
      component: () => import("@/page/login")
    },
    {
      path: "/noData",
      name: "查询结果",
      component: () => import("@/page/noData")
    },
    {
      path: "/success",
      name: "投保成功",
      component: () => import("@/page/insuranceSuccessful")
    },
    {
      path: "/insure",
      name: "小幸孕",
      component: () => import("@/page/insure")
    },
    {
      path: "/membershipCenter",
      name: "会员中心",
      component: () => import("@/page/membershipCenter")
    },
    {
      path: "/playback",
      name: "视频回放",
      component: () => import("@/page/playback")
    }
  ],
  mode: "history"
});

router.beforeEach((to, from, next) => {
  let token = getSession("token");
  console.log(token);
  if (to.path == "/membershipCenter") {
    if (!token) {
      return next("/login");
    }
  } else if (to.path == "/login") {
    if (token) {
      return next("/membershipCenter");
    }
  }
  next();
});
export default router;
